import React, { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import AuthContext from '../context/AuthContext';

const PrivateRoute = ({ children }) => {
  const { authTokens } = useContext(AuthContext);

  // If tokens are not found, redirect to login
  return authTokens ? children : <Navigate to="/login" />;
};

export default PrivateRoute;
